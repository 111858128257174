import './OpenChestButton.css';

const OpenChestButton = ({ onClick }) => {
  const click = () => {
    window.parent.postMessage({ height: 500 }, "*");
    onClick();
  };

  return <div className="button" onClick={click}>
    Open Chest
  </div>
};

export default OpenChestButton;
