import React from "react";
import { ROUND_COLORS } from "../App";
import Key from "../key/Key";
import Loading from "../loading/Loading";

import './Result.css'
import TryAgainButton from "../try-again/TryAgainButton";

const Result = ({ choices, result, resultLoading, onReset }) => {
  const Title = () => <h2>Opening the chest...</h2>;
  if (resultLoading) {
    return <React.Fragment>
      <Title />
      <Loading />
    </React.Fragment>;
  }

  return <div>
    <Title />
    <div className="-layout-h -justify-center">
      {Object.entries(choices).map((
        [roundIndex, keyIndex]) => <Key
          roundIndex={roundIndex}
          keyIndex={keyIndex}
          color={ROUND_COLORS[roundIndex - 1]}
          isClickable={false}
          isGlowing={true}
        />
      )}
    </div>

    <div className="remote-result" dangerouslySetInnerHTML={{
      __html: result.content
    }} />
    
    {result.status === 'closed' && <TryAgainButton onClick={onReset} />}
  </div>;
}

export default Result;