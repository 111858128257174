import { useCallback, useMemo, useState } from "react";
import Key from "../key/Key";
import Lock from "../lock/Lock";

import './Round.css';

function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

const Round = ({ roundIndex, color, onSelect }) => {
  const [selectedKey, setSelectedKey] = useState(null);
  const keys = useMemo(() => shuffle(Array.from({ length: 8 }).map((_, i) => i + 1)), []);
  const select = useCallback((roundIndex, keyIndex) => {
    onSelect(roundIndex, keyIndex);
    setSelectedKey(keyIndex);
  }, [setSelectedKey, onSelect]);
  
  return <div className="round">
    <Lock roundIndex={roundIndex} isOpened={false} />
    {[keys.slice(0, 4), keys.slice(4)].map((subset) => (
      <div className="-layout-h -justify-center">
        {subset.map((key, i) => (
          <Key
            key={i}
            roundIndex={roundIndex}
            keyIndex={key}
            color={color}
            isSelected={selectedKey === key}
            isDisabled={selectedKey !== null && selectedKey !== key}
            isClickable={true}
            onSelect={select}
          />
        ))}
      </div>
    ))}
  </div>;
};

export default Round;
