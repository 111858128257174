// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.key path {
  fill: var(--color);
}

.key {
  width: 160px;
  height: 100px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 25px 0;
  margin: 0 5px;
  border-radius: 20px;
  border: #fff 2px solid;
}

.key.disabled:hover {
  border: #ddd 2px solid;
}

.key.clickable:hover,
.key.selected {
  border: var(--color) 2px solid;
}


.key.disabled path {
  fill: #ddd;
}

.key svg {
  width: 160px;
  height: 100px;
}
`, "",{"version":3,"sources":["webpack://./src/key/Key.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;;EAEE,8BAA8B;AAChC;;;AAGA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,aAAa;AACf","sourcesContent":[".key path {\n  fill: var(--color);\n}\n\n.key {\n  width: 160px;\n  height: 100px;\n  overflow: hidden;\n  align-items: center;\n  justify-content: center;\n  padding: 25px 0;\n  margin: 0 5px;\n  border-radius: 20px;\n  border: #fff 2px solid;\n}\n\n.key.disabled:hover {\n  border: #ddd 2px solid;\n}\n\n.key.clickable:hover,\n.key.selected {\n  border: var(--color) 2px solid;\n}\n\n\n.key.disabled path {\n  fill: #ddd;\n}\n\n.key svg {\n  width: 160px;\n  height: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
