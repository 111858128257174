// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
  display: inline-block;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  outline: none;
  padding: 10px 25px;
  border-radius: 5px;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  overflow: hidden;

  background: blue;
  color: white;
}

.button:hover {
background: darkblue;
}

.button:active {
background: darkblue;
}

.button:focus {
  background: darkblue;
}
`, "",{"version":3,"sources":["webpack://./src/try-again/TryAgainButton.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,yBAAiB;UAAjB,iBAAiB;EACjB,eAAe;EACf,gBAAgB;;EAEhB,gBAAgB;EAChB,YAAY;AACd;;AAEA;AACA,oBAAoB;AACpB;;AAEA;AACA,oBAAoB;AACpB;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".button {\n  display: inline-block;\n  position: relative;\n  text-align: center;\n  text-transform: uppercase;\n  outline: none;\n  padding: 10px 25px;\n  border-radius: 5px;\n  user-select: none;\n  cursor: pointer;\n  overflow: hidden;\n\n  background: blue;\n  color: white;\n}\n\n.button:hover {\nbackground: darkblue;\n}\n\n.button:active {\nbackground: darkblue;\n}\n\n.button:focus {\n  background: darkblue;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
