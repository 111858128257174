import { ReactComponent as LockYellowOpened } from "../assets/lock-1-open.svg";
import { ReactComponent as LockYellowClosed } from "../assets/lock-1-closed.svg";
import { ReactComponent as LockBlueOpened } from "../assets/lock-2-open.svg";
import { ReactComponent as LockBlueClosed } from "../assets/lock-2-closed.svg";
import { ReactComponent as LockOrangeOpened } from "../assets/lock-3-open.svg";
import { ReactComponent as LockOrangeClosed } from "../assets/lock-3-closed.svg";
import { ReactComponent as LockGreenOpened } from "../assets/lock-4-open.svg";
import { ReactComponent as LockGreenClosed } from "../assets/lock-4-closed.svg";

import './Lock.css';

const lockSvgComponents = {
  1: {
    opened: LockYellowOpened,
    closed: LockYellowClosed,
  },
  2: {
    opened: LockBlueOpened,
    closed: LockBlueClosed,
  },
  3: {
    opened: LockOrangeOpened,
    closed: LockOrangeClosed,
  },
  4: {
    opened: LockGreenOpened,
    closed: LockGreenClosed,
  },
};

const Lock = ({ roundIndex, isOpened }) => {
  const LockSvgComponent = lockSvgComponents[roundIndex][isOpened ? 'opened' : 'closed'];
  return (
    <div className="lock -layout-v -center">
      <LockSvgComponent />
    </div>
  );
}

export default Lock;
