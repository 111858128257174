// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.remote-result {
  font-size: 1.5em;
  max-width: 500px;
  margin: 35px auto;
}

.remote-result .small {
  margin-top: 20px;
  font-size: 0.7em;
}`, "",{"version":3,"sources":["webpack://./src/result/Result.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".remote-result {\n  font-size: 1.5em;\n  max-width: 500px;\n  margin: 35px auto;\n}\n\n.remote-result .small {\n  margin-top: 20px;\n  font-size: 0.7em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
