import { useState } from 'react';
import './App.css';
import Round from './round/Round';
import { useCallback } from 'react';
import { useEffect } from 'react';
import Result from './result/Result';
import OpenChestButton from './open-chest/OpenChestButton';

export const ROUND_COLORS = ['#F9D851', '#72CECE', '#FF8E38', '#B4B63B'];

function sendCode(code, language) {
  return fetch('https://wedding-server.hoa.ro/code', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ code, language }),
  });
};

function App() {
  const [rounds, setRounds] = useState([]);
  const [roundCount, setRoundCount] = useState(1);
  const [choices, setChoices] = useState({});
  const [areAllKeysSelected, setAreAllKeysSelected] = useState(false);
  const [isGameFinished, setIsGameFinished] = useState(false);
  const [remoteResult, setRemoteResult] = useState(null);
  const [remoteResultLoading, setRemoteResultLoading] = useState(false);
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const language = urlParams.get('language');

    if (language && ['en', 'fr'].includes(language)) {
      setLanguage(language);
    };
  }, []);
  const onSelect = useCallback((roundIndex, keyIndex) => {
    if (isGameFinished) {
      return;
    }

    setChoices(choices => ({
      ...choices,
      [roundIndex]: keyIndex,
    }));
    setRoundCount(roundCount => Math.min(4, Math.max(roundIndex + 1, roundCount)));
  }, [setRoundCount, setChoices, isGameFinished]);

  useEffect(() => {
    setRounds(rounds => {
      if (rounds.length < roundCount) {
        return [
          ...rounds,
          <Round
            roundIndex={roundCount}
            key={roundCount}
            color={ROUND_COLORS[roundCount - 1]}
            onSelect={onSelect}
          />
        ]
      }

      return rounds;
    });
  }, [setRounds, onSelect, roundCount]);

  useEffect(() => {
    window.parent.postMessage({ height: 420 * roundCount }, "*");
  }, [roundCount]);

  useEffect(() => {
    if (roundCount === 4 && Object.keys(choices).length === 4) {
      setAreAllKeysSelected(true);
    }
  }, [roundCount, choices]);
  
  const onOpenChest = useCallback(async () => {
    setRemoteResultLoading(true);
    setIsGameFinished(true);
    
    const promises = [
      new Promise(async (resolve) => {
        const response = await sendCode(Object.values(choices).join(''), language);
        setRemoteResult((await response.json()));
        resolve();
      }),
      new Promise(resolve => setTimeout(resolve, 5000)),
    ];

    await Promise.all(promises);
    setRemoteResultLoading(false);
  }, [setIsGameFinished, setRemoteResult, choices, language]);

  const resetGame = useCallback(() => {
    setRounds([]);
    setRoundCount(1);
    setChoices({});
    setAreAllKeysSelected(false);
    setIsGameFinished(false);
    setRemoteResult(null);
  }, [setRounds, setRoundCount, setChoices, setAreAllKeysSelected, setIsGameFinished, setRemoteResult]);

  return (
    <div className="App">
      {!isGameFinished && rounds}
      {!isGameFinished && areAllKeysSelected  && <OpenChestButton onClick={onOpenChest} />}
      {isGameFinished && <Result
        choices={choices}
        resultLoading={remoteResultLoading}
        result={remoteResult}
        onReset={resetGame}
      />}
    </div>
  );
}

export default App;
