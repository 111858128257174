// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.round {
  padding: 10px 0;
  margin-bottom: solid 1px #eee;
}
`, "",{"version":3,"sources":["webpack://./src/round/Round.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,6BAA6B;AAC/B","sourcesContent":[".round {\n  padding: 10px 0;\n  margin-bottom: solid 1px #eee;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
